import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link as ScrollLink } from "react-scroll"; // Use react-scroll for smooth scrolling
import styles from "./Navbar.module.scss";
import logo2 from "../../Logo2.svg"; // Always using logo2

const NavBar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false); // Keep hover logic for the cool effect

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsMenuOpen(false); // Close menu after scrolling
  };

  return (
    <>
      <Helmet>
        <title>Vidipro | Professional Video and Photography</title>
        <meta
          name="description"
          content="Vidipro Video and Photo offers professional video and photography services. Explore our site for custom packages, stunning galleries, and more."
        />
        <meta
          name="keywords"
          content="Vidipro, navigation bar, menu, video, photo, El Paso, Las Cruces, about us, services, gallery, call now"
        />
        <meta name="author" content="Horizons Devs" />
        <meta property="og:title" content="Vidipro | Professional Video and Photography" />
        <meta
          property="og:description"
          content="Explore Vidipro’s website with our intuitive navigation bar. Seamlessly access all sections like Services, Gallery, and Contact Us for direct inquiries."
        />
        <meta property="og:image" content={logo2} />
        <meta property="og:url" content="https://vidiprophotovideo.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

      <nav className={styles.navbar}>
        <div className={styles.navbarContent}>
          {/* LOGO LINK - SCROLLS TO TOP ON CLICK */}
          <a
            href="#"
            className={styles.logoLink}
            onClick={(e) => {
              e.preventDefault();
              scrollToTop();
            }}
            onMouseEnter={() => setIsHovered(true)} // Trigger hover effect
            onMouseLeave={() => setIsHovered(false)} // Remove hover effect
          >
            <img
              className={styles.logoIcon}
              alt="Logo"
              src={logo2} // Always show logo2, but hover effects stay
              style={{ transform: isHovered ? "scale(1.1)" : "scale(1)", filter: isHovered ? "drop-shadow(0 0 6px rgba(255, 255, 255, 0.7))" : "drop-shadow(0 0 4px rgba(0, 0, 0, 0.5))" }}
            />
          </a>

          {/* HAMBURGER MENU BUTTON */}
          <button
            className={`${styles.hamburger} ${isMenuOpen ? styles.active : ""}`}
            onClick={toggleMenu}
          >
            <span className={styles.hamburgerLine}></span>
            <span className={styles.hamburgerLine}></span>
            <span className={styles.hamburgerLine}></span>
          </button>

          {/* NAVIGATION LINKS CONTAINER */}
          <div className={`${styles.navLinks} ${isMenuOpen ? styles.active : ""}`}>
            <ScrollLink
              to="services-section"
              smooth={true}
              duration={500}
              className={styles.linkItem}
              onClick={() => setIsMenuOpen(false)}
            >
              Services
            </ScrollLink>
            <ScrollLink
              to="about-section"
              smooth={true}
              duration={500}
              className={styles.linkItem}
              onClick={() => setIsMenuOpen(false)}
            >
              About Us
            </ScrollLink>
            <ScrollLink
              to="gallery-section"
              smooth={true}
              duration={500}
              className={styles.linkItem}
              onClick={() => setIsMenuOpen(false)}
            >
              Gallery
            </ScrollLink>
            <div className={styles.callNowContainer}>
              <a href="tel:+19152222787" className={styles.callNow}>
                Call Now!
              </a>
            </div>
          </div>
        </div>

        <a href="tel:+19152222787" className={styles.fixedCallNow}>
          Call Now!
        </a>
      </nav>
    </>
  );
};

export default NavBar;
