import React, { useState } from "react";
import styles from "./Services.module.scss"; // SCSS file for styles

const Services = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null); // Keeps track of the expanded item

  const services = [
    { title: "Photography", description: "Capture your special moments with stunning, high-quality photography that tells your story." },
    { title: "Video", description: "Relive every moment with professional videography that brings your memories to life." },
    { title: "Photobooth", description: "Add excitement to your event with our interactive photo booths and 360 video experiences." },
  ];

  const images = [
    "https://ucarecdn.com/50e7fae9-6951-4432-bc30-6b8754f19253/VIDIPRO16.JPG",
    "https://ucarecdn.com/1bba944b-8ea9-409b-ac80-31d2ee2b6bd2/VIDIPRO34.jpg",
    "https://ucarecdn.com/1477e945-84b5-41dd-8652-94744dc66c39/VIDIPRO30.JPG",
    "https://ucarecdn.com/6d1cff90-b3c6-45eb-b100-a86ea3059cf2/VIDIPRO32.jpg",
    "https://ucarecdn.com/b979fef8-0c4f-4611-8b17-4386d613c496/VIDIPRO35.jpg",
    "https://ucarecdn.com/b5757d19-bd3c-43fe-8e27-194214f5688e/VIDIPRO31.jpg",
    "https://ucarecdn.com/a06fa025-b8df-43e6-a45f-6516ec1d4eae/VIDIPRO27.JPG",
    "https://ucarecdn.com/bdde3444-4c39-4587-bb07-9a57f38dfba1/VIDIPRO8.JPG",
    "https://ucarecdn.com/b5a60304-6ba8-4ed1-83b0-d8150a4c144e/VIDIPRO18.JPG"
];

  return (
    <div className={styles.servicesContainer}>
      {/* Images Grid */}
      <div className={styles.imagesSection}>
        {images.map((image, index) => (
          <img key={index} src={image} alt={`Service ${index + 1}`} className={styles.serviceImage} loading="lazy" />
        ))}
      </div>

      {/* Services Description */}
      <div className={styles.textSection}>
        {services.map((service, index) => (
          <div
            key={index}
            className={`${styles.serviceItem} ${activeIndex === index ? styles.active : ""}`}
            onClick={() => setActiveIndex(activeIndex === index ? null : index)} // Toggle description
          >
            <h2 className={styles.serviceTitle}>{service.title}</h2>
            {activeIndex === index && <p className={styles.serviceDescription}>{service.description}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
