// src/components/About/About.tsx
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './About.scss'; // Custom styles

const About: React.FC = () => {
  const textSlides = [
    { title: 'About Us', content: 'With over 28 years of experience, Vidipro Video & Photo captures life’s best moments. We offer photography, videography, invitations, photo booths, 360 video booths, and premium printing on paper, acrylic, canvas, and metal.' },
    { title: 'Our Vision', content: 'To stay at the forefront of technology, continuously evolving to achieve success.' },
    { title: 'Our Mission', content: 'To deliver high-quality, personalized service that not only meets but exceeds our clients’ expectations.' },
    { title: 'Our Service', content: 'At Vidipro, we are dedicated to providing a professional and personalized experience from start to finish. We ensure that your most important moments are captured exactly as you envisioned, giving you the peace of mind to enjoy your special day to the fullest.' }
  ];

  return (
    <div className="about-section">
      {/* Text Swiper Carousel */}
      <div className="about-carousel">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          loop
          autoplay={{ delay: 5000 }}
          pagination={{ clickable: true }}
          navigation
        >
          {textSlides.map((slide, index) => (
            <SwiperSlide key={index} className="about-slide">
              <h1>{slide.title}</h1>
              <p>{slide.content}</p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Image Section */}
      <div className="about-image">
        <img src={require('../../assets/pictures/VIDIPRO20.webp')} alt="About Us" />
      </div>
    </div>
  );
};

export default About;
