import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'; 
import { Element, scroller } from 'react-scroll'; // Element and scroller to handle scrolling
import NavBar from './components/Navbar/Navbar';
import HeroCarousel from './components/Hero/Hero';
import About from './components/About/About';
import './App.scss';
import Services from './components/Services/Services';
import Gallery from './components/Gallery/Gallery';
import Footer from './components/Footer/Footer';

const App: React.FC = () => {
  // Scroll to section function
  const scrollToSection = (section: string) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  // Slide data for HeroCarousel with updated URLs to scroll to the Gallery and Services sections
  const slides = [
    {
      imageUrl: 'https://ucarecdn.com/58ebfbd5-037e-42ad-b709-d0e540dfdd4b/VIDIPRO24.JPG',
      title: 'Capture Your Moments',
      subtitle: 'Professional Photography and Videography',
      ctaLabel: 'Explore Our Services',
      ctaAction: () => scrollToSection('services-section'), // Scroll to Services section
    },
    {
      imageUrl: 'https://ucarecdn.com/5ccb9b14-f6d0-42d2-87c4-e54e66698899/VIDIPRO19.JPG',
      title: 'Create Lasting Memories',
      subtitle: 'Expert Wedding and Event Coverage',
      ctaLabel: 'See Our Work',
      ctaAction: () => scrollToSection('gallery-section'), // Scroll to Gallery section
    },
    {
      imageUrl: 'https://ucarecdn.com/932ba491-0f1e-4681-ac47-088a025f6423/VIDIPRO37.JPG',
      title: 'Personalized Packages',
      subtitle: 'Find the Perfect Package for Your Event',
      ctaLabel: 'Explore Our Services',
      ctaAction: () => scrollToSection('services-section'), // Another scroll to Services
    },
    {
      imageUrl: 'https://ucarecdn.com/7045cf8d-05a9-4eb7-8701-b01f78ad9733/VIDIPRO11.JPG',
      title: 'Contact Us',
      subtitle: 'Get in touch for custom event packages',
      ctaLabel: 'Call Now',
      ctaAction: () => window.location.href = 'tel:+19152222787', // Call now button
    }
  ];

  return (
    <Router>
      <div className="App">
        <NavBar /> {/* Navigation Bar */}
        <main className="App-content">
          {/* Hero Section */}
          <Element name="hero-section">
            <HeroCarousel slides={slides} />
          </Element>
          {/* Services Section */}
          <Element name="services-section">
            <Services />
          </Element>
          {/* About Section */}
          <Element name="about-section">
            <About />
          </Element>
          {/* Gallery Section */}
          <Element name="gallery-section">
            <Gallery />
          </Element>
          {/* Footer Section */}
          <Element name="footer-section">
            <Footer />
          </Element>
        </main>
      </div>
    </Router>
  );
};

export default App;
