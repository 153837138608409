import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Link as ScrollLink } from 'react-scroll'; // Import scroll link

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './Hero.scss';

interface Slide {
  imageUrl: string;
  title: string;
  subtitle?: string;
  ctaLabel?: string;
  ctaAction?: () => void; // Action instead of URL for more flexibility
}

interface HeroCarouselProps {
  slides: Slide[];
}

const HeroCarousel: React.FC<HeroCarouselProps> = ({ slides }) => {
  return (
    <div className="hero-carousel">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        loop
        autoplay={{ delay: 5000 }}
        pagination={{ clickable: true }}
        navigation
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className="carousel-slide">
            <img src={slide.imageUrl} alt={slide.title} className="carousel-image" />
            <div className="carousel-content">
              <h1 className="hero-title">{slide.title}</h1>
              {slide.subtitle && <p className="hero-subtitle">{slide.subtitle}</p>}
              {slide.ctaAction ? (
                // If there is an action, trigger it when the button is clicked
                <button onClick={slide.ctaAction} className="cta-button">
                  {slide.ctaLabel || 'Learn More'}
                </button>
              ) : (
                <ScrollLink
                  to={'services-section'} // Fallback scroll link
                  smooth={true}
                  duration={500}
                  className="cta-button"
                >
                  {slide.ctaLabel || 'Learn More'}
                </ScrollLink>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HeroCarousel;
