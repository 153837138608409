
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styles from './Gallery.module.scss';
import galleryMedia from "../../assets/pictures/galleryImages"; // Updated to galleryMedia for both images and videos

interface Media {
  src: string;
  type: "image" | "video";
}

const Gallery: React.FC = () => {
  const [activeMedia, setActiveMedia] = useState<Media | null>(null); // State to track the active media (image or video)
  const [isClosing, setIsClosing] = useState<boolean>(false); // State to track whether the modal is closing

  // Handle media click (image or video) to open the modal
  const handleMediaClick = (media: Media) => {
    setIsClosing(false); // Ensure the closing state is reset
    setActiveMedia(media); // Open the modal with the clicked media
  };

  // Handle closing the modal
  const handleClose = () => {
    setIsClosing(true); // Start the closing animation
    setTimeout(() => {
      setActiveMedia(null); // After animation, hide modal
      setIsClosing(false); // Reset closing state
    }, 300); // Match the animation duration
  };

  return (
    <>
      <Helmet>
        <title>Gallery | Vidipro</title>
        <meta
          name="description"
          content="Explore our gallery showcasing vibrant and engaging images of Vidipro's professional photo and video services."
        />
        <meta
          name="keywords"
          content="Vidipro gallery, photo and video services, event photos, professional images, El Paso, Las Cruces"
        />
        <meta name="author" content="Vidipro" />
        <meta property="og:title" content="Gallery | Vidipro" />
        <meta
          property="og:description"
          content="Check out the gallery of Vidipro, featuring exciting and lively photos of our professional photo and video services."
        />
        <meta property="og:image" content={galleryMedia[0].src} />
        <meta property="og:url" content="https://vidiprophotovideo.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

      {/* Gallery Section */}
      <div id="gallerySection" className={styles.gallerySection}>
        <h2 className={styles.galleryTitle}>Gallery</h2>
        <div className={styles.bentoGrid}>
          {/* Display all media (images and videos) */}
          {galleryMedia.map((media: Media, index: number) => (
            <button
              key={`media-${index}`}
              className={styles.bentoItem}
              onClick={() => handleMediaClick(media)} // Set active media on click
              aria-label={`Open enlarged media ${index + 1}`}
            >
              {media.type === "image" ? (
                <img
                  src={media.src}
                  alt={`Gallery Image ${index + 1}`}
                  className={styles.image}
                />
              ) : (
                <video
                  src={media.src}
                  className={styles.videoThumbnail}
                  muted
                  playsInline
                />
              )}
            </button>
          ))}
        </div>

        {/* Modal for showing enlarged media */}
        {activeMedia && (
          <div className={styles.modalOverlay} onClick={handleClose}>
            <div
              className={`${styles.modalContent} ${
                isClosing ? styles.closing : styles.active
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <span className={styles.closeButton} onClick={handleClose}>
                &times;
              </span>
              {activeMedia.type === "image" ? (
                <img
                  src={activeMedia.src}
                  alt="Enlarged view"
                  className={styles.enlargedImage}
                />
              ) : (
                <video
                  src={activeMedia.src}
                  className={styles.enlargedVideo}
                  controls
                  autoPlay
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Gallery;
