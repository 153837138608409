const galleryMedia: { src: string, type: "image" | "video" }[] = [
  { src: "https://ucarecdn.com/8dca0a1c-e73f-4729-95c0-1453a00a4399/VIDIPRO36.jpg", type: "image" },
  { src: "https://ucarecdn.com/6712223e-c76c-4438-a4da-063ae050b6f4/VIDIPRO12.JPG", type: "image" },
  { src: "https://ucarecdn.com/26dd80c5-6544-40dd-814f-f9ffa1f13853/VIDIPRO29.jpg", type: "image" },
  { src: "https://ucarecdn.com/932ba491-0f1e-4681-ac47-088a025f6423/VIDIPRO37.JPG", type: "image" },
  { src: "https://ucarecdn.com/7045cf8d-05a9-4eb7-8701-b01f78ad9733/VIDIPRO11.JPG", type: "image" },
  { src: "https://ucarecdn.com/a06fa025-b8df-43e6-a45f-6516ec1d4eae/VIDIPRO27.JPG", type: "image" },
  { src: "https://ucarecdn.com/1bba944b-8ea9-409b-ac80-31d2ee2b6bd2/VIDIPRO34.jpg", type: "image" },
  { src: "https://ucarecdn.com/5ccb9b14-f6d0-42d2-87c4-e54e66698899/VIDIPRO19.JPG", type: "image" },
  { src: "https://ucarecdn.com/b5757d19-bd3c-43fe-8e27-194214f5688e/VIDIPRO31.jpg", type: "image" },
  { src: "https://ucarecdn.com/ccf4b31a-8c92-4ea3-9eae-b016cf95b522/VIDIPRO14.JPG", type: "image" },
  { src: "https://ucarecdn.com/c9a39d15-d731-4d0e-a99c-4b2791289f39/VIDIPRO2.JPG", type: "image" },
  { src: "https://ucarecdn.com/1aab1bbc-02a9-46ad-bf32-5d6e578cb432/VIDIPRO13.JPG", type: "image" },
  { src: "https://ucarecdn.com/1477e945-84b5-41dd-8652-94744dc66c39/VIDIPRO30.JPG", type: "image" },
  { src: "https://ucarecdn.com/7e47f4ef-842c-4e6d-bca8-cc625292058c/VIDIPRO21.JPG", type: "image" },
  { src: "https://ucarecdn.com/e99125b1-edce-454f-86b3-0f255b5da0a5/VIDIPRO9.JPG", type: "image" },
  { src: "https://ucarecdn.com/6e77c384-f9db-4519-89a9-f6b99f02e392/VIDIPRO5.JPG", type: "image" },
  { src: "https://ucarecdn.com/880e8ffb-f5ea-4f10-a891-389e58db2d7f/VIDIPRO33.jpg", type: "image" },
  { src: "https://ucarecdn.com/8f718b99-246b-4668-998a-379cd5550205/VIDIPRO23.jpg", type: "image" },
  { src: "https://ucarecdn.com/98be4d89-d758-492e-b769-96fc05bafce9/VIDIPRO1.JPG", type: "image" },
  { src: "https://ucarecdn.com/db52b9b2-972c-4e85-a47b-78b3066ada3b/VIDIPRO22.JPG", type: "image" },
  { src: "https://ucarecdn.com/909d1391-6d42-448f-b4dd-96a98c85bd1c/VIDIPRO20.JPG", type: "image" },
  { src: "https://ucarecdn.com/b5a60304-6ba8-4ed1-83b0-d8150a4c144e/VIDIPRO18.JPG", type: "image" },
  { src: "https://ucarecdn.com/58ebfbd5-037e-42ad-b709-d0e540dfdd4b/VIDIPRO24.JPG", type: "image" },
  { src: "https://ucarecdn.com/3832565a-56e9-4465-b1df-65c6e19212aa/VIDIPRO3.JPG", type: "image" },
  { src: "https://ucarecdn.com/50e7fae9-6951-4432-bc30-6b8754f19253/VIDIPRO16.JPG", type: "image" },
  { src: "https://ucarecdn.com/801d8ca5-60e8-41c9-92a9-34009fab7e62/VIDIPRO17.JPG", type: "image" },
  { src: "https://ucarecdn.com/b1572fbf-c033-49b8-bc32-cfe0b5be28b7/VIDIPRO28.jpg", type: "image" },
  { src: "https://ucarecdn.com/a4535bc4-173d-4851-8bc7-e62c6701620e/VIDIPRO4.JPG", type: "image" },
  { src: "https://ucarecdn.com/16edc48f-e02a-4af7-81e2-715916dc7860/VIDIPRO39.jpeg", type: "image" },
  { src: "https://ucarecdn.com/bdde3444-4c39-4587-bb07-9a57f38dfba1/VIDIPRO8.JPG", type: "image" },
  { src: "https://ucarecdn.com/36626028-4479-479f-a47c-9ea7400d149d/VIDIPRO26.JPG", type: "image" },
  { src: "https://ucarecdn.com/6d1cff90-b3c6-45eb-b100-a86ea3059cf2/VIDIPRO32.jpg", type: "image" },
  { src: "https://ucarecdn.com/96d2706f-da93-4c29-b054-ecc569cbec9f/VIDIPRO6.JPG", type: "image" },
  { src: "https://ucarecdn.com/b979fef8-0c4f-4611-8b17-4386d613c496/VIDIPRO35.jpg", type: "image" },
  { src: "https://ucarecdn.com/beaa88d9-1c4e-4738-97f0-4e4d49363eb7/VIDIPRO7.JPG", type: "image" },
  { src: "https://ucarecdn.com/d742b5a9-7150-434b-90ac-8a49371802bd/VIDIPRO40.mp4", type: "video" },
];
  
  export default galleryMedia;
  