import React from "react";
import { Helmet } from "react-helmet"; // Import Helmet for managing meta tags
import styles from "./Footer.module.scss"; // Import your SCSS styles
import logo from "../../assets/LogoVidipro.svg"; // Assuming you have a Vidipro logo
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import phone from "../../assets/phone.svg";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>Vidipro - Professional Video and Photography</title>
        <meta
          name="description"
          content="Connect with Vidipro for professional video and photography services. Call us or follow us on Facebook and Instagram to stay updated."
        />
        <meta
          name="keywords"
          content="Vidipro, contact, social media, Facebook, Instagram, phone, video services, photography, El Paso, footer"
        />
        <meta name="author" content="Vidipro" />
        <meta property="og:title" content="Footer | Vidipro" />
        <meta
          property="og:description"
          content="Stay connected with Vidipro for professional video and photography services. Reach us by phone or follow us on Facebook and Instagram."
        />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content="https://vidiprophotovideo.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <footer className={styles.footerWrapper}>
        <div className={styles.footer}>
          <div className={styles.logoContainer}>
            <img 
              src={logo} 
              alt="Vidipro Logo" 
              className={styles.logo} 
              onClick={scrollToTop} // Add click handler to logo
              style={{ cursor: "pointer" }} // Change cursor to indicate clickability
            />
          </div>
          <div className={styles.iconsContainer}>
            <a href="tel:+19152222787" className={styles.iconLink}>
              <img src={phone} alt="Phone" className={styles.icon} />
            </a>
            <a
              href="https://www.facebook.com/vidiprovideoandphoto"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.iconLink}
            >
              <img src={facebook} alt="Facebook" className={styles.icon} />
            </a>
            <a
              href="https://www.instagram.com/vidipro/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.iconLink}
            >
              <img src={instagram} alt="Instagram" className={styles.icon} />
            </a>
          </div>
          <div className={styles.copyright}>
            © {currentYear} | VIDIPRO | All Rights Reserved
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
